.display-7xl {
  @apply font-maison-extended font-bold text-6xl lg:text-7xl leading-none tracking-tight;
}

.display-6xl {
  @apply font-maison-extended font-bold text-5xl lg:text-6xl leading-none tracking-tight;
}

.display-5xl {
  @apply font-maison-extended font-bold text-4xl lg:text-5xl leading-tight tracking-tight;
}

.display-4xl {
  @apply font-maison-extended font-bold text-3xl lg:text-4xl leading-tight tracking-tight;
}

.display-3xl {
  @apply font-maison-extended font-bold text-2xl lg:text-3xl leading-tight tracking-tight;
}

.display-2xl {
  @apply font-maison-extended font-bold text-xl lg:text-2xl leading-tight tracking-tight;
}

.display-xl {
  @apply font-maison-extended font-bold text-lg lg:text-xl leading-tight tracking-tight;
}

.wayfinding-sm {
  @apply font-maison-extended font-bold text-sm uppercase tracking-wide;
}

.wayfinding-xs {
  @apply font-maison-extended font-bold text-xs uppercase tracking-wide;
}

.statement-lg {
  @apply text-3xl lg:text-4xl tracking-tight leading-snug;
}

.statement-md {
  @apply text-2xl lg:text-3xl tracking-tight leading-snug;
}

.statement-sm {
  @apply text-xl lg:text-2xl tracking-tight leading-snug;
}
