.switch {
    @apply relative inline-block cursor-pointer select-none;

    &__input {
        @apply hidden;
    }

    & svg {
        @apply transform absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4/6 h-4/6 hidden text-primary-300;
    }

    &__container {
        @apply w-full h-full rounded-full bg-neutral-300;
        box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.07);
    }

    &__tumbler {
        @apply relative transform translate-x-0 rounded-full bg-white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &__input:checked + &__container {
        @apply bg-primary-300;
    }

    &__input:checked + &__container &__tumbler {
        @apply translate-x-full;
    }

    &__input:checked + &__container svg {
        @apply block;
    }
}
