.booking-funnel-calendar {
  padding-left: 24px !important;
  padding-right: 24px !important;
  z-index: 10 !important;

  .rbc-today {
    background-color: none !important;
  }

  .rbc-calendar {
    margin-top: 12px !important;

    .rbc-toolbar {
      flex-direction: row-reverse;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .rbc-timeslot-group {
    display: none;
  }
  .rbc-day-slot .rbc-event-content {
    display: none;
  }

  .rbc-time-slot:empty {
    background-image: url("data:image/svg+xml,%3Csvg width='110' height='50' viewBox='0 0 110 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='43.5' y1='16' x2='60.5' y2='16' stroke='%2394A3B8'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: 0;

  }
  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }
  .rbc-toolbar {
    flex-direction: row-reverse;
  }
  .rbc-time-gutter {
    display: none;
  }
  .rbc-time-header-content {
    display: none;
  }
  .rbc-time-content {
    overflow-y: hidden;
    border-top: none;

  }
  .rbc-row-content {
    display: none;
  }
  .rbc-timeslot-group {
    display: none;
    border-bottom: transparent;
  }

  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: none;
  }
  .rbc-time-content > * + * > * {
    border-left: 0px solid #ddd;
  }

  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    @apply bg-primary-50;
    // height: 4.3% !important;
  }

  .rbc-day-slot .rbc-event,
  .rbc-background-event {
    display: grid;
    place-items: center;
    position: initial;
    color: #713f12;
    background-color: #fef3c7;
    border-radius: 12px !important;
    margin: 2px;
    border: none;
  }

  .rbc-time-view {
    overflow-x: auto;
    border: none;

    .rbc-allday-cell {
      display: none;
    }
  }
  .rbc-row-content {
    display: none;
  }
  .rbc-timeslot-group {
    border-bottom: transparent;
  }
  .rbc-time-content > * + * > * {
    border-left: 0px solid #ddd;
  }

  .rbc-time-content > * + * > * {
    border-left: 0px solid #ddd;
  }
  .rbc-day-slot {
    .rbc-events-container {
      margin-right: 0px;
    }
    .rbc-event {
      justify-content: center;
      align-items: center;
      @apply bg-primary-50;
      // height: 4.3% !important;
      transition: background 200ms ease;

      &:hover {
        @apply bg-primary-100;

      }
    }
    .rbc-event,
    .rbc-background-event {
      border: 4px solid #ffffff;
      border-radius: 8px;
    }
    .rbc-event-label {
      text-align: center;
      color: #134e4a;
      font-weight: 500;
      width: 100%;
    }
  }
  .rbc-time-slot {
    height: 2.4rem !important;
    margin-top: 8px !important;

    flex: none;
  }
  .rbc-label.rbc-time-header-gutter,
  .rbc-time-gutter.rbc-time-column {
    display: none;
  }
  .rbc-event {
    justify-content: center;
    align-items: center;
    @apply bg-primary-50;
    // height: 4.3% !important;
    transition: background 200ms ease;
    width: 100% !important;
    top: 0px !important;
    height: 45px !important;

    &:hover {
      background: #319795;
      @apply bg-primary-100;
    }
  }
  .rbc-event,
  .rbc-background-event {
    border: 4px solid #ffffff;
    border-radius: 8px;
  }
  .rbc-event-label {
    font-weight: 500;
    padding: 0;
    text-align: center;
    width: 100%;
    font-size: 12px !important;
  }
}
.rbc-today {
  background-color: #fff !important;
}
