.how-it-works {
  counter-reset: howItWorksCounter;

  &__counter:before {
    counter-increment: howItWorksCounter;
    content: "0" counter(howItWorksCounter);
  }

  &__item {
    &:not(:last-child) {
      .how-it-works__itemConnector {
        @apply bg-neutral-200;
      }
    }
    &:last-child {
      .how-it-works__itemConnector {
        @apply bg-gradient-to-b from-neutral-200;
      }
    }
  }
}
