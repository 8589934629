.checkbox {
    @apply relative inline-block cursor-pointer select-none;

    &:hover &__icon {
        @apply border-neutral-950;
    }

    &:focus &__icon {
        @apply border-primary-300;
    }

    &__input {
        @apply hidden;
    }

    &__icon {
        @apply absolute top-0 left-0 flex items-center justify-center border-2 rounded w-full h-full border-neutral-300 text-primary-300 stroke-2;
    }

    &__icon svg {
        @apply hidden w-3/4 h-3/4;
    }

    &__input:checked + &__icon svg {
        @apply block;
    }

    &__input:checked + &__icon {
        @apply border-primary-300;
    }
}
