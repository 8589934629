body {
  @apply text-neutral-950 antialiased;
  scroll-behavior: smooth;
}

// Disabling chrome autofill background styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.primary-text-link {
  @apply font-semibold underline;

  &:hover {
    @apply no-underline;
  }
}

.capitalize-first:first-letter {
  text-transform: capitalize;
}

// Prevent AlpineJS flicker on page load
[x-cloak] {
  display: none !important;
}

//tooltip styles
.css-1meh2x2-BaseToolTop {
  width: 300px !important;
  left: 150px !important;
  border-radius: 6px !important;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.07),
    0px 10px 10px rgba(0, 0, 0, 0.03) !important;
}
.css-pyf0sc-BaseArrow {
  left: 10% !important;
}

.css-1h7jlxk-Bubble {
  background-color: white !important;
  border-radius: 6px !important;
}

.react-multi-carousel-item img {
  width: 100% !important;
}

.react-multiple-carousel__arrow {
  border-radius: 0px !important;
}

.react-tabs__tab--selected {
  @apply border-primary-300 text-neutral-950;
}

.react-tabs__tab--selected :active{
  border-width: 0px;
}

.react-tabs__tab--selected p {
  font-weight: bold !important;
}

@media (max-width: 1200px) {
  .css-1meh2x2-BaseToolTop {
    width: 200px !important;
    left: 100px !important;
  }
}
