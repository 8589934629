.search-map-custom-marker {
  @apply bg-primary-300 border-neutral-950 border font-medium text-neutral-950 transition-colors duration-200 shadow rounded;

  &:hover,
  &.active {
    @apply z-10;
  }

  &:not(.active):hover,
  &:not(.active):hover::before {
    @apply bg-primary-200;
  }

  &::before {
    @apply bg-primary-300 border-b border-r border-neutral-950 w-2 h-2 absolute bottom-0 left-1/2 transition-bg duration-200;
    content: "";
    transform: translate(-50%, 100%) rotate(45deg);
    top: calc(50% + 4px);
  }

  &.active,
  &.active::before {
    @apply bg-neutral-950 text-white border-neutral-950;
  }
}
