@import "tailwindcss/base";
@import "tailwindcss/components";
@import "base";
@import "typography";
@import "components/index";
@import "pages/index";
@import "tailwindcss/utilities";
@import "react-big-calendar/lib/css/react-big-calendar";
@import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
@import "./react-big-calendar-custom.scss";
@import "react-multi-carousel/lib/styles";

@layer utilities {
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
