.form-label {
  @apply block mb-2 text-neutral-800 font-semibold;
}

.input-floating-label {
  @apply relative;

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  select:focus ~ label,
  select:valid ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label {
    @apply pt-2 pl-3 text-xs text-neutral-600;
  }
}

.form-input.select {
  @apply relative;

  background: url("data:image/svg+xml,%3Csvg class='current-stroke' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
}

.custom-radio,
.custom-checkbox {
  @apply relative flex;

  &-label {
    @apply block pl-3 text-neutral-800 text-sm font-medium ml-0 pt-px cursor-pointer select-none;

    a {
      @apply font-semibold underline hover:no-underline;
    }
  }

  &-input {
    @apply w-5 h-5 shrink-0 border rounded-md outline-none shadow-sm appearance-none cursor-pointer border-neutral-300 hover:border-neutral-400 hover:bg-primary-50 focus:border-neutral-950 focus:outline-4 focus:outline-offset-0 focus:outline-primary-300/40 transition-all duration-200 z-10;

    &:before {
      content: "";
      @apply block opacity-0 transition-opacity;
    }

    &:checked, &.checked {
      @apply text-neutral-950 bg-primary-300 border-neutral-950;

       &:before {
        @apply opacity-100;
       }
    }
  }
}

.custom-radio {
  &-input {
    @apply flex flex-col justify-center items-center rounded-full;

    &:before {
      @apply bg-neutral-950 h-2.5 w-2.5 rounded-full flex-none;
    }
  }

  &-label {
    @apply capitalize;
  }
}

.custom-checkbox {
  &-input {
    @apply rounded-md;

    &:before {
      @apply w-full h-full p-0.5 bg-no-repeat bg-center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke-width='3' stroke='currentColor' viewBox='0 0 24 24' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 12l5 5l10 -10'%3E%3C/path%3E%3C/svg%3E");
    }
  }
}


.conditional-radio {
  > div:nth-child(2) {
    @apply mt-2 hidden;
  }
}

input[type="date"] {
  min-height: 2.75rem;
}

input[disabled] {
  background-color: inherit;
}
