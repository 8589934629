.button {
  @apply py-3 px-5 inline-flex items-center justify-center space-x-2 font-medium text-center border rounded-lg transition-all duration-150 ease-in-out cursor-pointer whitespace-nowrap outline-none focus:outline focus:shadow-none focus:outline-offset-0 focus:outline-4 disabled:cursor-not-allowed;

  &-primary {
    @extend .button;
    
    @apply bg-primary-300 border-neutral-950 text-neutral-950;

    &:hover {
      @apply bg-primary-200;
    }

    &:focus {
      @apply outline-primary-200;
    }
  }

  &-primary-outline {
    @apply bg-transparent border-primary-300 text-primary-300;

    &:hover {
      @apply bg-primary-50;
    }

    &:focus {
      @apply outline-primary-100;
    }
  }

  &-secondary {
    @extend .button;

    @apply bg-neutral-950 border-neutral-950 text-white hover:bg-neutral-800 focus:outline-neutral-300 disabled:bg-neutral-300 disabled:border-neutral-300;
  }

  &-secondary-outline {
    @apply border-neutral-950 bg-transparent text-neutral-950;

    &:hover {
      @apply bg-neutral-950/5;
    }

    &:focus {
      @apply outline-neutral-950/20;
    }
  }

  &-tertiary {
    @apply bg-transparent border-transparent text-neutral-700;

    &:hover {
      @apply bg-neutral-100 text-neutral-800 border-neutral-100;
    }

    &:focus {
      @apply bg-neutral-100;
    }
  }

  &-gray {
    @extend .button;
    @apply bg-white shadow-sm border-neutral-300 text-neutral-700;

    &:hover {
      @apply bg-neutral-50 text-neutral-800;
    }

    &:focus {
      @apply outline-neutral-100;
    }
  }

  &-danger {
    @apply bg-danger-50 text-danger-600 border-danger-50;

    &:hover {
      @apply bg-danger-600 text-white;
    }

    &:focus {
      @apply border-danger-600;
    }
  }

  &-xs {
    @apply text-xs py-1 px-2;
  }

  &-sm {
    @apply py-2 px-4 text-sm;
  }

  &-lg {
    @apply py-4 px-7 text-lg;
  }
}
