.location-carousel {
  .react-multi-carousel-track {
    flex-grow: 1;
    justify-content: stretch;

    .react-multi-carousel-item {
      img {
        object-fit: cover;
        height: 100%;
        max-height: 450px;
      }
    }
  }
}
